import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import ReactLink from "@micado-digital/react-link/ReactLink";

import styles from "./Links.styles";

const Links = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const [ui, setUi] = useContext(UiContext);
	const { menuGroups } = pageData;
	const menuGroup = menuGroups?.find(menuGroup => menuGroup?.title === "Menülinks");

	return (
		<div className={clsx(css.menulinks, "mco-view-component-header-menulinks")}>
			{menuGroup?.items?.map(item => {
				const { id, link, linkRel, selected, target, title } = item;
				return (
					<ReactLink
						key={id}
						className={clsx(css.link, "mco-view-component-header-menulinks__link", {
							[css.linkSelected]: selected,
							"mco-view-component-header-menulinks__link--selected": selected
						})}
						onClick={e => {
							setUi({ ...ui, menuOpen: false });
						}}
						rel={linkRel}
						target={target}
						to={link}
					>
						{title}
					</ReactLink>
				);
			})}
		</div>
	);
};

export default Links;
