import React from "react";
import Elements from "@Components/Elements";

const Default = ({ lang }) => {
	const { REACT_APP_PATH } = process.env;

	return (
		<>
			<Elements />
			<micado-ial language={lang} path={REACT_APP_PATH}></micado-ial>
		</>
	);
};

export default Default;
