import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	hamburger: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		marginRight: theme.spacing(-0.5),
		width: 32,
		[theme.breakpoints.up("md")]: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(0),
			width: 40
		}
	}
}));

export default styles;
