import loadable from "@loadable/component";

const AroundMe = loadable(() => import("../../lib/AroundMe"));
const Container = loadable(() => import("@Components/views/Container"));
const Form = loadable(() => import("@micado-digital/react-form"));
const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactReferences02 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences02")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactHTMLExtended = loadable(() => import("@micado-digital/react-html/ReactHTML02"));
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactSingleImage01 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage01")
);
const ReactImageText01 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText01")
);
const ReactGallery04 = loadable(() => import("@micado-digital/react-gallery/ReactGallery04"));
const ReactGallery05 = loadable(() => import("@micado-digital/react-gallery/ReactGallery05"));
const ReactSingleTeaser01 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser01")
);
const ReactTeaserList01 = loadable(() =>
	import("@micado-digital/react-teaser-list/ReactTeaserList01")
);
const ReactTeaser01 = loadable(() => import("@micado-digital/react-teaser/ReactTeaser01"));
const ReactTeaser03 = loadable(() => import("@micado-digital/react-teaser/ReactTeaser03"));
const ReactEBookTeaser01 = loadable(() =>
	import("@micado-digital/react-ebook-teaser/ReactEBookTeaser01")
);
const ReactRoutePlanner = loadable(() =>
	import("@micado-digital/react-route-planner/ReactRoutePlanner")
);
const ReactWeather03 = loadable(() => import("@micado-digital/react-weather/ReactWeather03"));
const ReactSnowReport01 = loadable(() =>
	import("@micado-digital/react-snowreport/ReactSnowReport01")
);
const QuickSearch = loadable(() => import("@Elements/QuickSearch"));
const CustomButton = loadable(() => import("@Elements/Button"));
const Quote = loadable(() => import("@Elements/Quote"));
const Ratings = loadable(() => import("@Elements/Ratings"));
const TextboxTeaser = loadable(() => import("@Elements/TextboxTeaser"));
const TourismRoomList = loadable(() => import("@Elements/TourismRoomList"));
const TourismPackageList = loadable(() => import("@Elements/TourismPackageList"));
const TourismPackagesTeaser = loadable(() => import("@Elements/TourismPackagesTeaser"));
const ImageListCombo = loadable(() => import("@Elements/ImageListCombo"));
const ReactSearchResults = loadable(() =>
	import("@micado-digital/react-search-results/ReactSearchResults")
);

const getJSXElement = (element, lang, sysLink, screenMD, l) => {
	const { REACT_APP_PATH } = process.env;
	const {
		addition,
		addition2,
		anchor,
		children,
		elements,
		id,
		media,
		option,
		option2,
		reference,
		tag,
		text,
		textcontent,
		teasergroup,
		title,
		variant
	} = element;

	switch (tag) {
		case "basic-headline": {
			return (
				<Container
					key={id}
					tag={tag}
					variant={variant === 6 ? "overline" : (variant || 0) + 1}
					maxWidth="lg"
					anchor={anchor?.tag}
				>
					<ReactHeadline01 text={text} variant={variant === 6 ? 5 : variant} />
				</Container>
			);
		}

		case "basic-text": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="lg"
					anchor={anchor?.tag}
					variant={variant === 1 ? "large" : ""}
				>
					<ReactText01 text={text} />
				</Container>
			);
		}

		case "basic-list": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="lg"
					anchor={anchor?.tag}
					variant={variant === 0 ? "two-columns" : ""}
				>
					<ReactList01
						headlineVariant="h2"
						textcontent={textcontent}
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			return (
				<Container
					key={id}
					maxWidth="lg"
					tag={tag}
					anchor={anchor?.tag}
					variant={variant === 1 ? "large" : ""}
				>
					<ReactImageText01
						addition2={addition2}
						alt={addition}
						headlineVariant="h2"
						option={option}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "imagetext-mobile",
							sm: variant === 1 ? "imagetext-large" : "imagetext"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			const { elements } = element;

			const breakpoints = {
				0: {
					slidesPerView: 1.2,
					spaceBetween: 16
				},
				500: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				960: {
					slidesPerView: 3.5,
					spaceBetween: 20
				},
				1280: {
					slidesPerView: 3.8,
					spaceBetween: 20
				},
				1440: {
					slidesPerView: 4.2,
					spaceBetween: 20
				},
				1700: {
					slidesPerView: 4.5,
					spaceBetween: 20
				},
				1920: {
					slidesPerView: 5.5,
					spaceBetween: 20
				},
				2560: {
					slidesPerView: 6.5,
					spaceBetween: 20
				}
			};

			if (variant === 1) {
				return (
					<Container
						key={id}
						maxWidth={false}
						tag={tag}
						anchor={anchor?.tag}
						style={{ padding: 0 }}
						variant="textbox"
					>
						<ReactGallery05
							elements={elements}
							mediaFormats={{
								xs: "basic-gallery-mobile",
								sm: "basic-gallery-big"
							}}
							navNext="/img/icons/arrow-right.svg"
							navPrev="/img/icons/arrow-left.svg"
							showPagination={true}
							showNavigation={true}
						/>
					</Container>
				);
			} else {
				return (
					<Container key={id} tag={tag} anchor={anchor?.tag} style={{ padding: 0 }}>
						<ReactGallery04
							breakpoints={breakpoints}
							elements={elements}
							lang={lang}
							loop={true}
							mediaFormats={{
								xs: "basic-gallery-thumb"
							}}
							navNext="/img/icons/arrow-right.svg"
							navPrev="/img/icons/arrow-left.svg"
							overlayMediaFormats={{
								xs: "basic-gallery"
							}}
							showNavigation={true}
							SwiperProps={{
								centeredSlides: true,
								spaceBetween: 20
								// slidesPerView: screenMD ? "auto" : 1
							}}
						/>
					</Container>
				);
			}
		}

		case "basic-table": {
			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			if (!newTableArray || newTableArray.length === 0) return <></>;

			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactTable01
						addition={addition}
						headlineVariant="h3"
						items={newTableArray}
						title={title}
						sticky={variant === 1 ? true : false}
					/>
				</Container>
			);
		}

		case "basic-links": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactReferences02
						buttonVariant="outlined"
						elements={elements}
						title={title}
						hasIcon={false}
						headlineVariant="h2"
					/>
				</Container>
			);
		}

		case "basic-video": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactVideo02
						media={media[0]}
						mediaFormats={{ xs: "video-mobile", sm: "video" }}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactHTML html={text} />
				</Container>
			);
		}

		case "basic-html-extended": {
			const { id, textcontent } = element;

			const identifier = textcontent?.items?.find(
				item => item?.name === "valIdentifier"
			)?.text;
			const externalScript = textcontent?.items?.find(
				item => item?.name === "valExternalScript"
			)?.text;
			const html = textcontent?.items?.find(item => item?.name === "valHTML")?.text;
			const script = textcontent?.items?.find(item => item?.name === "valScript")?.text;

			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactHTMLExtended
						externalScript={externalScript}
						html={html}
						id={identifier ?? id}
						script={script}
					/>
				</Container>
			);
		}

		case "basic-download-group": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactFile01 icon="/img/icons/download.svg" media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-dropdown": {
			return null;
		}

		case "basic-dropdown-group": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="lg"
					anchor={element?.children?.[0]?.anchor?.tag}
					variant={element?.children?.[0]?.variant === 1 ? "full-width" : ""}
				>
					<ReactDropDown01
						headlineVariant="h3"
						option={element?.children?.[0]?.option2}
						title={element?.children?.[0]?.title}
						icon={<ReactSVGIcon src="/img/icons/plus.svg" />}
					>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleteaser": {
			const { id, teaser, text, title, variant, teaser: { media } = {} } = element;
			const teaserData = {
				addition: teaser?.addition,
				id: id,
				link: teaser?.link,
				media: media,
				text: text ? text : teaser?.text,
				title: title ? title : teaser?.title
			};

			let elementVariant = null;

			switch (variant) {
				case 1:
					elementVariant = "left";
					break;

				case 2:
					elementVariant = "notext-right";
					break;

				case 3:
					elementVariant = "notext-left";
					break;

				default:
					break;
			}

			return (
				<Container
					key={id}
					maxWidth="lg"
					tag={tag}
					variant={elementVariant}
					style={{ padding: 0 }}
					anchor={anchor?.tag}
				>
					<ReactSingleTeaser01
						buttonLabel={l("reactSingleTeaser01.button")}
						buttonVariant="text"
						headlineVariant="h2"
						teaser={teaserData}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser-mobile",
							sm: "teaser",
							md: "singleteaser"
						}}
						variant={1}
					/>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			const addition = textcontent?.items?.find(item => item?.name === "valAddition")?.text;
			const title = textcontent?.items?.find(item => item?.name === "valTitle")?.text;
			const text = textcontent?.items?.find(item => item?.name === "valText")?.text;
			const link = textcontent?.items?.find(item => item?.name === "valUrl")?.text;
			const media = elements?.find(item => item?.tag === "valImage")?.media;

			const teaser = {
				addition: addition,
				title: title,
				text: text,
				link: link,
				linkTarget: "_blank"
			};

			return (
				<Container key={id} tag={tag} anchor={anchor?.tag}>
					<ReactSingleTeaser01
						buttonLabel={l("reactSingleTeaser01.button")}
						buttonVariant="contained"
						headlineVariant="h3"
						icon="/img/icons/chevron-right.svg"
						teaser={teaser}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser-mobile",
							sm: "basic-teaser"
						}}
						variant={1}
					/>
				</Container>
			);
		}

		case "basic-singleimage": {
			return (
				<Container key={id} tag={tag} anchor={anchor?.tag} style={{ padding: 0 }}>
					<ReactSingleImage01
						addition={addition}
						option2={option2}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "singleimage-mobile",
							sm: variant === 1 ? "singleimage-sub" : "singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						reference={reference}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-form": {
			return (
				<Container key={id} maxWidth="lg" tag={tag} anchor={anchor?.tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST" key={id}>
						<Connector formElements={elements} formID={id} />
					</Form>
				</Container>
			);
		}

		case "basic-teaser": {
			if (variant === 1) {
				return (
					<Container key={id} tag={tag} variant="slider" maxWidth={false} anchor={anchor?.tag}>
						<ReactTeaserList01
							autoplay={false}
							delay={5000}
							headline={title}
							headlineVariant="h2"
							loop={false}
							navNext="/img/icons/arrow-right.svg"
							navPrev="/img/icons/arrow-left.svg"
							showPagination={false}
							showNavigation={screenMD ? true : false}
							sliderBreakpoints={{
								0: {
									slidesPerView: 1.2
								},
								600: {
									slidesPerView: 2.2
								},
								960: {
									slidesPerView: 3.2
								},
								1280: {
									slidesPerView: "4.2"
								},
								1920: {
									slidesPerView: "5.2"
								},
								2560: {
									slidesPerView: "6.2"
								}
							}}
							speed={1000}
							text={text}
							variant="slider"
							wrapNavigation={true}
						>
							{teasergroup?.items?.map(({ id, addition, link, media, title, linkTarget }) => {
								return (
									<ReactTeaser03
										addition={addition}
										key={id}
										link={link}
										media={media ? media?.[0] : {}}
										mediaFormats={{
											xs: "teaser-slider"
										}}
										mediaPath={REACT_APP_PATH}
										target={linkTarget}
										title={title ? title : ""}
									/>
								);
							})}
						</ReactTeaserList01>
					</Container>
				);
			} else if (variant === 2) {
				return (
					<Container
						key={id}
						tag={tag}
						variant="textbox"
						maxWidth={false}
						anchor={anchor?.tag}
					>
						<TextboxTeaser
							headline={title}
							lang={lang}
							text={text}
							items={teasergroup?.items}
						/>
					</Container>
				);
			} else {
				return (
					<Container
						key={id}
						tag={tag}
						maxWidth={false}
						variant="slider-default"
						anchor={anchor?.tag}
					>
						<ReactTeaserList01
							autoplay={false}
							delay={5000}
							headline={title}
							headlineVariant="h2"
							loop={false}
							navNext="/img/icons/arrow-right.svg"
							navPrev="/img/icons/arrow-left.svg"
							showPagination={false}
							showNavigation={screenMD ? true : false}
							sliderBreakpoints={{
								0: {
									slidesPerView: 1.2
								},
								600: {
									slidesPerView: 2.2
								},
								960: {
									slidesPerView: 3.2
								},
								1280: {
									slidesPerView: "4.2"
								},
								1920: {
									slidesPerView: "5.2"
								},
								2560: {
									slidesPerView: "6.2"
								}
							}}
							speed={1000}
							text={text}
							variant="slider"
							wrapNavigation={true}
						>
							{teasergroup?.items?.map(
								({ id, addition, link, media, price, title, text, linkTarget, from, to }) => {
									return (
										<ReactTeaser01
											addition={addition}
											date={{ dateFrom: from, dateTo: to }}
											key={id}
											link={link}
											linkElement={true}
											media={media ? media?.[0] : {}}
											mediaFormats={{
												xs: "teaser"
											}}
											mediaPath={REACT_APP_PATH}
											price={price}
											target={linkTarget}
											text={text}
											title={title ? title : ""}
										/>
									);
								}
							)}
						</ReactTeaserList01>
					</Container>
				);
			}
		}

		case "extension-quickbooking": {
			return (
				<Container key={id} maxWidth="md" tag={tag}>
					<QuickSearch lang={lang} />
				</Container>
			);
		}

		case "extension-ebook": {
			let title = textcontent?.items?.find(e => e.name === "title")?.text;
			let text = textcontent?.items?.find(e => e.name === "text")?.text;
			let media = elements?.find(e => e.media)?.media?.[0];
			let filename = elements?.[0]?.media?.find(e => e.filename)?.filename;
			let lastPathName = filename?.substring(filename.lastIndexOf("/") + 1);
			let download = elements?.[0]?.media?.find(e => e.fileType === "PDF")?.filename;
			let browseURL = lastPathName?.replace(/\.[^/.]+$/, "") + `.html`;

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactEBookTeaser01
						browse={`${REACT_APP_PATH}/ebook/${browseURL}`}
						browseIcon={"/img/icons/book.svg"}
						download={download}
						downloadIcon={"/img/icons/download.svg"}
						lang={lang}
						media={media}
						mediaFormats={{
							xs: "ebook-teaser"
						}}
						text={text}
						title={title}
					/>
				</Container>
			);
		}

		case "extension-route-planner": {
			return (
				<Container key={id} maxWidth={false} tag={tag}>
					<ReactRoutePlanner
						lang={lang}
						stroke={{
							width: 5
						}}
						startMarker={{
							anchor: [24, 48],
							popupAnchor: [0, -48],
							size: [48, 48],
							url: "/img/icons/marker-route-start.svg"
						}}
						targetMarker={{
							address: "Hütten 40, 5771 Leogang",
							coords: [47.440828257326054, 12.714741568439367],
							marker: {
								anchor: [24, 48],
								popupAnchor: [0, -48],
								size: [48, 48],
								url: "/img/icons/marker-route-target.svg"
							},
							popup: {
								lat: 47.440828257326054,
								lng: 12.714741568439367,
								street: "Hütten 40",
								title: "Biohotel Rupertus",
								town: "Leogang",
								zipCode: "5771"
							}
						}}
						token="5b3ce3597851110001cf6248f737a58988964497b4c787dbb689f8e8"
					/>
				</Container>
			);
		}

		case "extension-button": {
			const label = textcontent?.items?.find(item => item?.name === "valLabel")?.text;
			const link = elements?.find(item => item?.tag === "valLink")?.reference?.pageName;
			const externalLink = textcontent?.items?.find(
				item => item?.name === "valExternalLink"
			)?.text;
			const target = textcontent?.items?.find(item => item?.name === "valTarget")?.text;

			if (!link && !externalLink) {
				return;
			}

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<CustomButton
						label={label}
						link={link ? link : externalLink}
						target={externalLink && target === "True" ? "_blank" : "_top"}
					/>
				</Container>
			);
		}

		case "extension-quote": {
			const addition = textcontent?.items?.find(item => item?.name === "valAddition")?.text;
			const author = textcontent?.items?.find(item => item?.name === "valAuthor")?.text;
			const text = textcontent?.items?.find(item => item?.name === "valText")?.text;
			const media = elements?.find(item => item?.tag === "valImage")?.media;

			return (
				<Container
					key={id}
					maxWidth={false}
					tag={tag}
					variant={variant === 1 ? "greyscale" : ""}
				>
					<Container maxWidth="lg">
						<Quote addition={addition} author={author} text={text} media={media?.[0]} />
					</Container>
				</Container>
			);
		}

		case "extension-rating": {
			return (
				<Container key={id} maxWidth={false} tag={tag}>
					<Container maxWidth="lg">
						<Ratings lang={lang} />
					</Container>
				</Container>
			);
		}

		case "extension-weather": {
			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactWeather03
						lang={lang}
						url={`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.WeatherApi/GetDetailsAsJson.api`}
					/>
					<div
						className="mco-weather-03-copyright"
						variant="body2"
						dangerouslySetInnerHTML={{ __html: l("weather.copyrightZamg") }}
						style={{ fontSize: 14, marginTop: 8 }}
					/>
				</Container>
			);
		}

		case "mski-snowreport": {
			let client = null;
			let region = null;

			textcontent?.items?.filter(item => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				return null;
			});

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactSnowReport01
						client={client}
						iconSize={32}
						identifierAvalanche="avalance-level"
						identifierDateLastSnowfall="date-last-snowfall"
						identifierSnowHeightValley="snow-height-valley"
						identifierSnowHeightMountain="snow-height-mountain"
						lang={lang}
						region={region}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.PropertyApi/ListProperties.api`}
					/>
				</Container>
			);
		}

		case "tourism-room-list": {
			return (
				<Container key={id} maxWidth={false} tag={tag}>
					<Container maxWidth="lg">
						<TourismRoomList lang={lang} />
					</Container>
				</Container>
			);
		}

		case "tourism-package-list": {
			return (
				<Container key={id} maxWidth={false} tag={tag}>
					<Container maxWidth="lg">
						<TourismPackageList lang={lang} />
					</Container>
				</Container>
			);
		}

		case "tourism-packages-teaser": {
			return (
				<Container key={id} maxWidth={false} tag={tag}>
					<TourismPackagesTeaser element={element} lang={lang} />
				</Container>
			);
		}

		case "extension-image-list-combo": {
			const { children } = element;

			return (
				<ImageListCombo key={id} tag={tag} anchor={anchor?.tag}>
					{children?.map(item => getJSXElement(item))}
				</ImageListCombo>
			);
		}

		case "extension-search-result": {
			return (
				<Container anchor={anchor} maxWidth="lg" key={id} tag={tag}>
					<ReactSearchResults
						api={`${REACT_APP_PATH}/searchv2.json.api`}
						detailUrl={sysLink("search")}
						icon={
							<ReactSVGIcon src={REACT_APP_PATH + "/img/icons/chevron-right.svg"} size={24} />
						}
						lang={lang}
					/>
				</Container>
			);
		}

		case "extension-around-me": {
			return (
				<Container
					anchor={anchor}
					key={id}
					maxWidth={false}
					noPadding
					tag={tag}
					style={{ marginTop: 0 }}
				>
					<AroundMe
						apiKey="5b3ce3597851110001cf6248f737a58988964497b4c787dbb689f8e8"
						config={{
							cluster: {
								size: [34, 45]
							},
							mainMarker: {
								anchor: [17, 45],
								popupAnchor: [0, -45],
								size: [34, 45]
							},
							subMarker: {
								anchor: [17, 45],
								popupAnchor: [0, -45],
								size: [34, 45]
							}
						}}
						mediaFormats={{
							xs: "infrastructure-teaser"
						}}
						LeafletMapProps={{
							maps: [
								{
									attribution:
										'<a href="https://www.micado.cc" target="_blank" rel="noopener noreferrer nofollow">Map by Micado</a> | Source: <a href="http://osm.org/copyright" target="_blank" rel="noopener noreferrer nofollow">OpenStreetMap</a>',
									url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								}
							]
						}}
					/>
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
