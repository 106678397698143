import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";

const Slider = lazy(() => import(/* webpackChunkName: "reactAnimationSlider" */ "./slider"));
const Arrows = lazy(() => import(/* webpackChunkName: "reactAnimationSlider" */ "./arrows"));

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flex: 1,
		height: "100%",
		overflow: "hidden"
	},
	wrapper: {
		display: "flex",
		flex: 1,
		position: "relative"
	}
}));

const ReactAnimation = ({
	absoluteMediaURLs = false,
	api,
	data,
	id,
	lang = "de",
	mediaFormats,
	textComponents = { text1: "p", text2: "p" },
	textVariants = { text1: "h1", text2: "h3" },
	webpFallback = false
}) => {
	const [options, setOptions] = useState(data ?? {});
	const { entries = 0, items = [] } = options;
	const [slide, setSlide] = useState(null);
	const _animationTimer = useRef();
	const _isPlaying = useRef();
	const screenMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const css = useStyles();

	useEffect(() => {
		setTimeout(() => {
			_isPlaying.current = true;

			setSlide(0);
		}, 100);
	}, [options]);

	useEffect(() => {
		clearTimeout(_animationTimer.current);
	}, []);

	useEffect(() => {
		if (!entries) return;

		play();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entries, slide]);

	useEffect(() => {
		setTimeout(() => {
			setSlide(0);
		}, 100);
	}, [options]);

	useEffect(() => {
		const playAnimation = () => {
			clearTimeout(_animationTimer.current);

			_animationTimer.current = setTimeout(() => {
				if (slide === entries - 1) {
					setSlide(0);
				} else {
					setSlide(slide + 1);
				}
			}, 5000);
		};

		playAnimation();
	}, [entries, slide]);

	useEffect(() => {
		if (data) return;

		const fetchData = async () => {
			let fetchURL = `${api}?lang=${lang}`;

			if (id) fetchURL += `&id=${id}`;

			const response = await fetch(fetchURL);

			if (response.status !== 200) {
				throw new Error("No animation found!");
			}

			const data = await response.json();

			setOptions(data);

			return data;
		};

		fetchData();
	}, [api, data, id, lang]);

	const handleChange = id => {
		_isPlaying.current = true;

		const slideID = id ?? slide + 1;

		if (slideID >= entries) {
			setSlide(0);
			return;
		}

		setSlide(slideID);
	};

	const play = () => {
		if (!_isPlaying.current) return;

		clearTimeout(_animationTimer.current);

		_animationTimer.current = setTimeout(() => {
			if (slide === entries - 1) {
				setSlide(0);
			} else {
				setSlide(slide + 1);
			}
		}, 5000);
	};

	const stop = () => {
		_isPlaying.current = false;

		clearTimeout(_animationTimer.current);
	};

	return (
		<Box className={clsx("mco-animation-01", css.root)}>
			<Box className={clsx("mco-animation-01__wrapper", css.wrapper)}>
				{entries > 0 && (
					<Suspense fallback={<></>}>
						<Slider
							SliderID={id}
							absoluteMediaURLs={absoluteMediaURLs}
							active={slide}
							entries={entries}
							handleArrowChange={id => handleChange(id)}
							items={items}
							mediaFormats={mediaFormats}
							playAnimation={handleChange}
							stopAnimation={stop}
							textStyles={{ textComponents, textVariants }}
							webpFallback={webpFallback}
						/>
					</Suspense>
				)}
				{entries > 1 && screenMD && (
					<Suspense fallback={<></>}>
						<Arrows active={slide} entries={entries} onChange={id => handleChange(id)} />
					</Suspense>
				)}
			</Box>
		</Box>
	);
};

ReactAnimation.propTypes = {
	absoluteMediaURLs: PropTypes.bool,
	api: PropTypes.string,
	id: PropTypes.number,
	images: PropTypes.array,
	lang: PropTypes.string,
	mediaFormats: PropTypes.object,
	prevIcon: PropTypes.object,
	nextIcon: PropTypes.object,
	showArrows: PropTypes.bool,
	webpFallback: PropTypes.bool
};

export default ReactAnimation;
