import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { useScrollYPosition } from "react-use-scroll-position";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import BookmarkIcon from "@micado-digital/react-shop/components/BookmarkIcon";
import CartIcon from "@micado-digital/react-shop/components/CartIcon";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import Container from "../Container";
import Hamburger from "./Hamburger";
import Lang from "./Lang";
import Logo from "./Logo";
import Phone from "./Phone";
import Search from "./Search";
import Quicklinks from "./Quicklinks";
import MobileMenu from "./Menu/MobileMenu";
import DesktopMenu from "./Menu/DesktopMenu";

import styles from "./Header.styles";

const Header = ({ layoutTag }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [pageData] = useContext(PageContext);
	const { fields, menuGroups } = pageData;
	const [ui] = useContext(UiContext);
	const { _loading } = ui;
	const screenMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const menuGroup = menuGroups?.find(menuGroup => menuGroup?.title === "Hauptmenü");
	const colorTheme = fields?.find(fields => fields?.title === "Farbe")?.value?.value;
	const theme = useTheme();
	const css = styles(colorTheme, theme);

	useEffect(() => {
		if (!_loading) {
			setMenuOpen(false);
		}
	}, [_loading]);

	const scrollY = useScrollYPosition();
	const stickyHeader = scrollY > 88;

	const Menu = () => {
		if (screenMD) {
			return <DesktopMenu menuGroup={menuGroup} menuOpen={menuOpen} />;
		} else {
			return <MobileMenu menuGroup={menuGroup} />;
		}
	};

	return (
		<div
			className={clsx(css.header, "mco-view-component-header", {
				[css.stickyHeader]: stickyHeader,
				[css.menuActive]: menuOpen,
				[css.headerRoomDetail]: layoutTag === "room-detail"
			})}
		>
			<Container
				className={clsx(css.container, "mco-view-component-header__container")}
				maxWidth="lg"
			>
				<Logo />
				<Quicklinks />
				{screenMD && <Lang />}
				<div className={clsx(css.icons, "mco-view-component-header__icons")}>
					<Phone />
					<Search />
					<BookmarkIcon />
					<CartIcon />
				</div>
				<Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
			</Container>
			{menuOpen && Menu()}
		</div>
	);
};

export default Header;
