import React, { useEffect, useState } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: "flex-start",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		height: 82,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			alignItems: "center",
			textAlign: "center"
		},
		[theme.breakpoints.up("md")]: {
			height: 92
		}
	},
	wrapper: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center"
	},
	button: {
		cursor: "pointer",
		display: "flex",
		outline: "none",
		marginTop: -8,
		padding: theme.spacing(1),
		"&.mco-quicksearch-valueField__button-less": {
			marginLeft: theme.spacing(-1),
			[theme.breakpoints.up("sm")]: {
				marginLeft: 0
			}
		}
	},
	buttonDisabled: {
		opacity: 0.32
	},
	value: {
		display: "flex",
		fontSize: 27,
		fontWeight: "bold",
		justifyContent: "center",
		minWidth: 32,
		[theme.breakpoints.up("md")]: {
			fontSize: 34,
			minWidth: 64
		}
	}
}));

const Adults = ({ label, name, value = 0, onChange }) => {
	const [counter, setCounter] = useState(value);
	const css = useStyles();

	let incrementCounter = () => setCounter(counter + 1);
	let decrementCounter = () => setCounter(counter - 1);

	if (counter <= 0) {
		decrementCounter = () => setCounter(0);
	}

	if (counter >= 6) {
		incrementCounter = () => setCounter(10);
	}

	useEffect(() => {
		const adults = {
			name: name,
			counter: counter
		};

		onChange(adults);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counter]);

	return (
		<div className={clsx(css.root, "mco-quicksearch-valueField")}>
			<Typography variant="caption">{label}</Typography>
			<div className={clsx(css.wrapper, "mco-quicksearch-valueField__wrapper")}>
				<div
					className={clsx("mco-quicksearch-valueField__button-less", css.button, {
						[css.buttonDisabled]: counter <= 0
					})}
					onClick={decrementCounter}
				>
					<ReactSVGIcon src="/img/icons/booking-person-less.svg" size={24} color="initial" />
				</div>
				<Typography className={clsx(css.value, "mco-quicksearch-valueField___value")}>
					{counter}
				</Typography>
				<div
					className={clsx("mco-quicksearch-valueField__button-more", css.button, {
						[css.buttonDisabled]: counter >= 6
					})}
					onClick={incrementCounter}
				>
					<ReactSVGIcon src="/img/icons/booking-person-more.svg" size={24} color="initial" />
				</div>
			</div>
		</div>
	);
};
export default Adults;
