import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import useFetch from "@micado-digital/react-ui/hooks/useFetch";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";
import QuickSearch from "@Components/Elements/QuickSearch";
import Container from "@Container";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./RoomDetail.styles";

const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactGallery05 = loadable(() => import("@micado-digital/react-gallery/ReactGallery05"));

const RoomDetail = ({ lang }) => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const data = useFetch(
		`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.ProductApi/Details.api?lang=${lang}&pagename=${window.location.pathname}`
	);
	const { _loading } = data || {};

	const attributes = data?.attributes?.map(item => {
		return item?.title;
	});

	const formatCapacity = () => {
		if (data?.minAdults && data?.maxAdults && data?.minAdults !== data?.maxAdults) {
			return `${data?.minAdults}-${data?.maxAdults}`;
		} else {
			return data?.maxAdults;
		}
	};

	return (
		<>
			{_loading ? (
				<></>
			) : (
				<>
					<div className={clsx(css.intro, "mco-room-detail-intro")}>
						<Container maxWidth="lg">
							<div className={css.textWrapper}>
								<Typography component="p" dangerouslySetInnerHTML={{ __html: data?.title }} />
								<div className={css.facts}>
									<div>
										<Typography component="span" variant="caption">
											<Local identifier="roomDetail.suitableFor" l={l} />
										</Typography>
										<Typography component="p" variant="h1">
											{`${formatCapacity()} `}
											<Local identifier="roomDetail.persons" l={l} />
										</Typography>
									</div>
									<Divider orientation="vertical" flexItem />
									<div>
										<Typography component="span" variant="caption">
											<Local identifier="roomDetail.roomSize" l={l} />
										</Typography>
										<Typography component="p" variant="h1">
											{data?.size} m²
										</Typography>
									</div>
								</div>
							</div>
							<div className={css.imageWrapper}>
								<ReactHTMLPicture
									media={data?.gallery?.[0]?.media?.[0]}
									mediaFormats={{ xs: "product-medium", md: "product-large" }}
								/>
							</div>
						</Container>
					</div>
					<div className={clsx(css.elements, "mco-room-detail-elements")}>
						<Container maxWidth="md" tag="extension-quickbooking">
							<QuickSearch
								code={data?.interfaceIdentifier}
								lang={lang}
								productID={data?.id}
								providerID={1}
							/>
						</Container>
						<Container maxWidth="lg" tag="basic-headline" variant={1}>
							<ReactHeadline01 text={data?.title} variant={0} />
						</Container>
						<Container tag="basic-text" maxWidth="lg" variant="large">
							<ReactText01 text={data?.descriptions?.[0]?.description} />
						</Container>
						<Container
							maxWidth={false}
							tag="basic-gallery"
							style={{ padding: 0 }}
							variant="textbox"
						>
							<ReactGallery05
								absoluteMediaURLs
								elements={data?.gallery}
								mediaFormats={{
									xs: "product-medium",
									md: "product-large"
								}}
								navNext="/img/icons/arrow-right.svg"
								navPrev="/img/icons/arrow-left.svg"
								showPagination={true}
								showNavigation={true}
							/>
						</Container>
						<Container maxWidth="lg" tag="basic-list">
							<ReactList01
								headlineVariant="h2"
								data={attributes}
								title={l("roomDetail.attributes")}
							/>
						</Container>
					</div>
				</>
			)}
			<micado-ial language={lang} path={REACT_APP_PATH} page-id={pageData?.id}></micado-ial>
		</>
	);
};

export default RoomDetail;
