import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		alignItems: "center",
		background: theme.palette.brand.brand4,
		padding: theme.spacing(3, 2),
		display: "flex",
		flexDirection: "column-reverse",
		gap: theme.spacing(1),
		justifyContent: "center",
		[theme.breakpoints.up("md")]: {
			flexDirection: "row-reverse",
			gap: theme.spacing(4.5),
			padding: theme.spacing(4, 2)
		},
		"& a": {
			alignItems: "center",
			display: "flex",
			flexDirection: "column-reverse",
			gap: theme.spacing(1.5),
			justifyContent: "center",
			[theme.breakpoints.up("md")]: {
				flexDirection: "row-reverse",
				gap: theme.spacing(4.5)
			}
		}
	},
	text: {
		display: "flex",
		textAlign: "center"
	},
	stars: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.up("md")]: {
			marginTop: -4
		}
	}
}));

export default styles;
