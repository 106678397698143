import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	megamenu: {
		display: "flex",
		flexDirection: "column",
		inset: 0,
		overflow: "hidden",
		position: "fixed",
		width: "100%",
		zIndex: theme.zIndex.drawer
	},
	container: {
		minHeight: "100%",
		overflowX: "hidden",
		overflowY: "auto"
	},
	desktopmenu: {
		display: "grid",
		gridColumnGap: theme.spacing(6),
		gridRowGap: theme.spacing(4),
		gridTemplateColumns: "2fr 1fr",
		gridTemplateRows: "1fr auto",
		height: "calc(100vh - 88px)",
		minHeight: "100%",
		padding: theme.spacing(9, 2, 6),
		"@media (min-width: 960px) and (max-height: 780px)": {
			padding: theme.spacing(6, 2)
		}
	},
	submenuActive: {
		"& .mco-view-component-desktopmenu__link": {
			opacity: 0.5
		},
		"& .mco-view-component-desktopmenu__link--selected": {
			opacity: 1
		}
	},
	column1: {
		gridColumn: "1 / 2",
		gridRow: "1 / 2"
	},
	column2: {
		gridColumn: "2 / -1",
		gridRow: "1 / 2",
		position: "relative",
		zIndex: 1
	},
	bottom: {
		alignItems: "center",
		display: "flex",
		gap: theme.spacing(4),
		gridColumn: "1 / -1",
		gridRow: "2 / -1",
		paddingBottom: theme.spacing(3),
		"& .mco-view-component-header-menulinks": {
			display: "flex",
			gap: theme.spacing(4.5),
			"& a": {
				margin: 0
			}
		},
		"& .mco-social-icons": {
			margin: 0
		}
	},
	divider: {
		background: theme.palette.divider,
		display: "flex",
		height: 1,
		marginTop: -4,
		width: 80
	}
}));

export default styles;
