import makeStyles from "@material-ui/styles/makeStyles";

const styles = (level, theme) => {
	let linkStyles = null;
	let itemStyles = null;

	switch (level) {
		case 1:
		default:
			itemStyles = {
				marginTop: theme.spacing(1.5)
			};
			linkStyles = {
				...theme?.customTypography?.menu?.desktop?.level1,
				opacity: 0.5,
				"&:hover": {
					opacity: 0.8
				}
			};
			break;
		case 2:
			itemStyles = {
				marginTop: theme.spacing(2)
			};
			linkStyles = {
				...theme?.customTypography?.menu?.desktop?.level2,
				"&:hover": {
					textDecoration: "underline",
					textDecorationThickness: "1.5px",
					textUnderlineOffset: "4px"
				}
			};
			break;
	}

	return makeStyles(theme => ({
		item: {
			...itemStyles
		},
		link: {
			position: "relative",
			...linkStyles
		},
		selected: {
			color: theme.palette.primary.main,
			opacity: 1
		}
	}))();
};

export default styles;
