import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import MobileContainer from "./Level1";
import Lang from "../../Lang";
import Links from "../Links";
import SocialMediaIcons from "../../../SocialMediaIcons";

import styles from "./MobileMenu.styles";

const MobileMenu = ({ menuGroup }) => {
	const css = styles();
	const { items } = menuGroup;
	const ref = useRef(null);

	useEffect(() => {
		disableBodyScroll(ref.current);

		return () => {
			clearAllBodyScrollLocks();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={clsx(css.mobilemenu, "mco-view-component-header-menu-mobilemenu")}
			ref={ref}
		>
			<div
				className={clsx(css.container, "mco-view-component-header-menu-mobilemenu__container")}
			>
				<MobileContainer items={items} />
				<div
					className={clsx(css.divider, "mco-view-component-header-menu-mobilemenu__divider")}
				>
					<Lang />
					<SocialMediaIcons />
				</div>
				<div className={clsx(css.links, "mco-view-component-header-menu-mobilemenu__links")}>
					<Links />
				</div>
			</div>
		</div>
	);
};

export default MobileMenu;
