import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		"& .mco-breadcrumb": {
			alignItems: "center",
			flexWrap: "unset",
			fontSize: 0,
			gap: theme.spacing(0.5),
			listStyle: "none",
			margin: 0,
			overflow: "auto",
			padding: "10px 0",
			position: "relative",
			whiteSpace: "nowrap",
			width: "100%",
			zIndex: 0,
			"&::-webkit-scrollbar": {
				display: "none"
			},
			[theme.breakpoints.up("lg")]: {
				maxWidth: 720
			}
		},
		"& .mco-breadcrumb-item": {
			display: "inline-block"
		},
		"& .mco-breadcrumb-item__link": {
			fontSize: 13
		},
		"& .mco-breadcrumb-item__icon": {
			marginTop: -4
		}
	}
}));

export default styles;
