import React, { useContext } from "react";
import clsx from "clsx";
import vhCheck from "vh-check";
import useTheme from "@material-ui/core/styles/useTheme";
import PageContext from "@PageContext";
import ReactAnimation from "./CustomAnimation";
import Weather from "./Weather";
import Breadcrumbs from "./Breadcrumbs";

import styles from "./Animation.styles";

const Animation = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { animationID, fields, lang, layoutVariant } = pageData;
	const colorTheme = fields?.find(fields => fields?.title === "Farbe")?.value?.value;
	const theme = useTheme();
	const css = styles(colorTheme, theme);
	vhCheck({
		bind: true
	});

	return (
		<div
			className={clsx(css.animation, "mco-view-component-animation", {
				[css.bigAnimation]: layoutVariant === "big-animation",
				[css.hasBreadcrumbs]:
					pageData?.breadcrumbs?.length > 0 && pageData?.breadcrumbs?.[0]?.title !== "Home"
			})}
		>
			<ReactAnimation
				api={`${REACT_APP_PATH}/animationV3.json.api`}
				id={animationID}
				lang={lang}
				mediaFormats={{
					xs: "animation-mobile",
					sm: layoutVariant === "big-animation" ? "animation" : "animation-small"
				}}
				webpFallback
			/>
			{pageData?.breadcrumbs?.[0]?.title === "Home" && (
				<div className={css.weatherButton}>
					<Weather />
				</div>
			)}
			{pageData?.breadcrumbs?.length > 0 && pageData?.breadcrumbs?.[0]?.title !== "Home" && (
				<div
					className={clsx(css.breadcrumbs, {
						[css.breadcrumbsRight]: layoutVariant === "big-animation"
					})}
				>
					<Breadcrumbs />
				</div>
			)}
		</div>
	);
};

export default Animation;
