import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	phone: {
		padding: theme.spacing(0.5),
		"&:hover": {
			opacity: 0.8
		}
	}
}));

export default styles;
