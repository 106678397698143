import React from "react";
import clsx from "clsx";
import Booking from "./Booking";
import Enquiry from "./Enquiry";
import Voucher from "./Voucher";

import styles from "./Quicklinks.styles";

const Quicklinks = () => {
	const css = styles();

	return (
		<div className={clsx(css.quicklinks, "mco-view-component-header_quicklinks")}>
			<Enquiry />
			<Booking />
			<Voucher />
		</div>
	);
};

export default Quicklinks;
