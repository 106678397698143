import React from "react";
import { Routes, Route } from "react-router-dom";
import Elements from "@Components/Elements";
import ProductOverview01 from "@micado-digital/react-shop/views/ProductOverview01";
import Shop01 from "@micado-digital/react-shop/views/Shop01";

const Shop = ({ lang }) => {
	return (
		<>
			<Routes>
				<Route
					path="/:lang/shop/*"
					element={
						<div className="mco-shop">
							<Shop01 lang={lang} />
						</div>
					}
				/>
				<Route
					path="/:lang/shop"
					element={
						<div className="mco-shop">
							<ProductOverview01 defaultLang={lang} showCategories={false} />
						</div>
					}
				/>
				<Route path="/*" element={<Elements />} />
			</Routes>
		</>
	);
};

export default Shop;
