import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		gridTemplateRows: "1fr 1fr",
		gridColumnGap: theme.spacing(2),
		gridRowGap: theme.spacing(5),
		overflow: "hidden",
		[theme.breakpoints.up("sm")]: {
			gridTemplateColumns: "1fr 1fr 1fr 1px 165px",
			gridTemplateRows: "auto",
			gridColumnGap: theme.spacing(3),
			"& .mco-quicksearch-button": {
				alignItems: "center",
				display: "flex",
				marginLeft: "auto",
				width: "100%",
				"& button": {
					width: "100%"
				}
			}
		},
		"@media (min-width: 700px)": {
			gridTemplateColumns: "1fr 1fr 1fr 1px 180px"
		},
		"@media (min-width: 800px)": {
			gridColumnGap: theme.spacing(5)
		},
		[theme.breakpoints.up("md")]: {
			gridColumnGap: theme.spacing(6),
			marginRight: theme.spacing(5)
		}
	},
	divider: {
		background: theme.palette.divider,
		display: "flex",
		height: "100%",
		width: 1
	}
}));

export default styles;
