import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	socialIcons: {
		display: "flex",
		flexWrap: "wrap",
		gap: theme.spacing(3),
		marginBottom: theme.spacing(6)
	},
	icon: {
		"&:hover svg path": {
			opacity: 0.8
		}
	}
}));

export default styles;
