import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		justifyContent: "space-between",
		padding: theme.spacing(2, 3)
	}
}));

const Buttons = ({ confirmLabel, resetLabel, onConfirm, onReset }) => {
	const css = useStyles();

	return (
		<div className={clsx(css.root, "mco-quicksearch-01__buttons")}>
			<Button color="primary" variant="outlined" onClick={onReset}>
				{resetLabel}
			</Button>
			<Button color="secondary" variant="contained" onClick={onConfirm}>
				{confirmLabel}
			</Button>
		</div>
	);
};

export default Buttons;
