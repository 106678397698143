import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		alignItems: "center",
		background: "#FFFFFF",
		borderRadius: "50%",
		display: "flex",
		height: 56,
		justifyContent: "center",
		width: 56
	}
}));

export default styles;
