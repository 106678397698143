import React, { useContext } from "react";
import ReactBreadcrumb from "@micado-digital/react-breadcrumb/ReactBreadcrumb";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import PageContext from "@PageContext";

import styles from "./Breadcrumbs.styles";

const Breadcrumbs = () => {
	const [pageData] = useContext(PageContext);
	const css = styles();

	const data = pageData?.breadcrumbs;

	return (
		<div className={css.root}>
			<ReactBreadcrumb
				data={data}
				homeLabel="Rupertus"
				homeLink="/"
				separator={<ReactSVGIcon size={24} src="/img/icons/bookmark-separator.svg" />}
			/>
		</div>
	);
};

export default Breadcrumbs;
