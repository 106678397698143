import React from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./Hamburger.styles";

const Hamburger = ({ menuOpen, setMenuOpen }) => {
	const screenMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const css = styles();

	return (
		<div
			className={clsx(css.hamburger, "mco-view-component-header-menu-mobilemenu__hamburger")}
			onClick={() => setMenuOpen(!menuOpen)}
		>
			{menuOpen && (
				<ReactSVGIcon
					color="primary"
					src={screenMD ? "/img/icons/close.svg" : "/img/icons/close-mobile.svg"}
					size={screenMD ? 40 : 24}
				/>
			)}
			{!menuOpen && (
				<ReactSVGIcon
					color="primary"
					src={screenMD ? "/img/icons/menu.svg" : "/img/icons/menu-mobile.svg"}
					size={screenMD ? 40 : 24}
				/>
			)}
		</div>
	);
};

export default Hamburger;
