import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	search: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		height: 24,
		width: 24
	}
}));

export default styles;
