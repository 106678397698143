import isEmpty from "lodash/isEmpty";
import useWeatherAPI from "./useWeatherAPI";

const useCurrentWeather = ({ lang = "de", station, url }) => {
	const data = useWeatherAPI(0, lang, station, url)[0]?.[0];
	const { days = [] } = data || {};
	const { state, temperatureMax, times } = days[0] || [];

	const detectedType = detectType();

	let x = times?.find(time => time.type === detectedType);

	if (isEmpty(x) && detectedType === "night") {
		x = times?.find(time => time.type === "evening");
	}

	if (isEmpty(x)) {
		return {
			state,
			temperature: temperatureMax
		};
	}

	return {
		state: x.state,
		temperature: x.temperature
	};
};

const detectType = () => {
	const now = new Date();
	const hours = now.getHours();

	if (hours < 12) {
		return "morning";
	} else if (hours >= 12 && hours < 17) {
		return "afternoon";
	} else if (hours >= 17 && hours < 20) {
		return "evening";
	} else {
		return "night";
	}
};

export default useCurrentWeather;
