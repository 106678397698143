import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: "flex-start",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		height: 82,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			alignItems: "center",
			textAlign: "center"
		},
		[theme.breakpoints.up("md")]: {
			height: 92
		}
	},
	value: {
		display: "flex",
		flexDirection: "column",
		"& p": {
			fontSize: 27,
			fontWeight: "bold",
			[theme.breakpoints.up("md")]: {
				fontSize: 34
			}
		},
		"& span": {
			fontWeight: "bold",
			fontSize: 13,
			letterSpacing: "1px"
		}
	}
}));

const DateField = ({ label, lang, onClick, placeholder = "", value }) => {
	const css = useStyles();

	const date = value?.fullDate;
	const day = lang === "de" || lang === "it" ? date?.split(".")[0] : date?.split("/")[1];
	const month = lang === "de" || lang === "it" ? date?.split(".")[1] : date?.split("/")[0];
	const year = value?.year;

	return (
		<>
			<div className={clsx(css.root, "mco-quicksearch-field")} onClick={onClick}>
				{label && (
					<Typography
						className={clsx(css.label, "mco-quicksearch-field__label")}
						variant="caption"
					>
						{label}
					</Typography>
				)}
				{value ? (
					<div className={clsx(css.value, "mco-quicksearch-field__value")}>
						<Typography>
							{lang === "de" || lang === "it" ? `${day}.${month}.` : `${month}/${day}`}
						</Typography>
						<Typography component="span">{year}</Typography>
					</div>
				) : (
					<div className={clsx(css.placeholder, "mco-quicksearch-field__placeholder")}>
						<Typography>{placeholder}</Typography>
					</div>
				)}
			</div>
		</>
	);
};

export default DateField;
