import { useEffect, useState } from "react";
import axios from "axios";
import isNil from "lodash/isNil";

const useWeatherAPI = (days, lang, station, url) => {
	const [data, setData] = useState({ loading: true });

	useEffect(() => {
		const getWeather = async () => {
			const params = [];
			params.push(`lang=${lang}`);

			if (!isNil(days)) params.push(`days=${days}`);
			if (station) params.push(`stations=${station}`);

			const fetchURL = `${url}?${params.join("&")}`;

			const result = await axios.post(fetchURL);

			if (result?.status === 200) {
				setData(result?.data);
				return;
			}

			setData({});
		};

		if (lang && url) {
			getWeather();
		}
	}, [days, lang, station, url]);

	return [data, setData];
};

export default useWeatherAPI;
