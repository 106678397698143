import React from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import ConditionalWrapper from "@micado-digital/react-ui/components/ConditionalWrapper";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Ratings.styles";

const Ratings = ({ lang }) => {
	const sysLink = useSystemLinks();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	const stars = [...Array(6)].map((x, i) => (
		<ReactSVGIcon key={i} src="/img/icons/star.svg" size={24} />
	));

	return (
		<>
			<div className={clsx(css.root, "extension-ratings")}>
				<ConditionalWrapper
					condition={sysLink("ratings")}
					wrapper={children => <ReactLink to={sysLink("ratings")}>{children}</ReactLink>}
				>
					<Typography className={clsx(css.text, "extension-ratings__text")} variant="caption">
						<Local identifier="ratings.text" l={l} />
					</Typography>
					<div className={clsx(css.stars, "extension-ratings__stars")}>{stars}</div>
				</ConditionalWrapper>
			</div>
		</>
	);
};

export default Ratings;
