import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logo: {
		alignItems: "center",
		display: "flex",
		marginRight: "auto",
		width: 100,
		[theme.breakpoints.up("sm")]: {
			width: 138
		},
		[theme.breakpoints.up("md")]: {
			width: 165
		}
	},
	image: {
		display: "block",
		width: "100%"
	}
}));

export default styles;
