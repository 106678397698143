import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	link: {
		display: "table",
		marginBottom: theme.spacing(1.5),
		position: "relative",
		...theme?.customTypography?.footer?.footermenu,
		"&:hover": {
			textDecoration: "underline",
			textDecorationThickness: "1.5px",
			textUnderlineOffset: "2px"
		}
	}
}));

export default styles;
