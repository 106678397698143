import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import ConsentData from "@micado-digital/react-ui/components/ConsentData";
import UserData from "@micado-digital/react-ui/components/UserData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";
import Locals from "@micado-digital/react-ui/components/Locals";
import BookmarkRoot from "@micado-digital/react-bookmark/ReactBookmark/bookmarkRoot";
import ShopData from "@micado-digital/react-shop/components/Shop";
import useScrollToLocation from "../hooks/useScrollToLocation";
import useIsCurrentLocation from "@micado-digital/react-ui/hooks/useIsCurrentLocation";
import Animation from "@Components/views/Animation";
import Header from "@Components/views/Header";
import Default from "./Default";
import RoomDetail from "./RoomDetail";
import Shop from "./Shop";
import Core from "./Core";
import Ratings from "@Components/Elements/Ratings";

import styles from "./Views.styles";

const Footer = loadable(() => import("@Components/views/Footer"));

const Views = () => {
	const [pageData] = useContext(PageContext);
	const { authorID, lang, layoutTag, layoutVariant } = pageData;
	const currentLocation = useIsCurrentLocation();
	const css = styles();

	useScrollToLocation(120);

	// prevent Viomo 404 error -> new tab

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const links = document.querySelectorAll("a.vsc-button, a.vri-pagination-link");

			const handleContextMenu = event => {
				event.preventDefault(); // Prevents the context menu from appearing
			};

			const handleAuxClick = event => {
				if (event.button === 1) {
					event.preventDefault(); // Prevents the middle-click from opening a new tab
				}
			};

			if (links?.length === 0) {
				return;
			}

			links.forEach(link => {
				link.addEventListener("contextmenu", handleContextMenu);
				link.addEventListener("auxclick", handleAuxClick);
				link.rel = "nofollow";
			});
		}, 3000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [currentLocation]);

	return (
		<WebProfile key={lang}>
			<ConsentData lang={lang}>
				<AccountData authorID={authorID}>
					<UserData>
						<ShopData lang={lang}>
							<BookmarkRoot>
								<Locals url={`/locals/${lang || "de"}.json`}>
									<div className={clsx(css.page, "mco-page", `mco-page-${layoutTag}`)}>
										<Header layoutTag={layoutTag} />
										{layoutTag === "standard" && layoutVariant !== "no-animation" && (
											<Animation />
										)}
										<PageTransition color="rgba(0, 0, 0, 0.5)" position="top" />
										{layoutTag === "standard" && <Default lang={lang} />}
										{layoutTag === "shop" && <Shop lang={lang} />}
										{layoutTag === "room-detail" && <RoomDetail lang={lang} />}
										<Ratings lang={lang} />
										<Footer />
									</div>
									<Core />
								</Locals>
							</BookmarkRoot>
						</ShopData>
					</UserData>
				</AccountData>
			</ConsentData>
		</WebProfile>
	);
};

export default Views;
