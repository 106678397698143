import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	booking: {
		"&:hover": {
			textDecoration: "underline",
			textDecorationThickness: "1.5px",
			textUnderlineOffset: "2px"
		}
	}
}));

export default styles;
