import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	mobilemenu: {
		background: theme.palette.background.menu,
		bottom: 0,
		left: 0,
		width: "100%",
		zIndex: 1000,
		position: "fixed",
		overflowX: "hidden",
		overflowY: "auto",
		top: 60
	},
	container: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(5),
		height: "100%",
		padding: theme.spacing(3, 2),
		position: "relative",
		zIndex: 100,
		"& .mco-view-component-header-menu-mobilemenu__links": {
			paddingBottom: theme.spacing(2)
		}
	},
	divider: {
		borderBottom: "1px solid " + theme.palette.divider,
		borderTop: "1px solid " + theme.palette.divider,
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between",
		padding: theme.spacing(3, 2),
		"& .mco-social-icons": {
			marginBottom: 0
		}
	}
}));

export default styles;
