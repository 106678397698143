import React, { Suspense } from "react";
import loadable from "@loadable/component";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";
import Buttons from "../Buttons";

const ReactOverlay = loadable(() => import("@micado-digital/react-overlay/ReactOverlay"));
const ReactDatepicker = loadable(() =>
	import("@micado-digital/react-datepicker/ReactDatepicker")
);

const Datepicker = ({
	lang,
	productID,
	providerID,
	rangeSelected,
	setRangeSelected,
	setOpen,
	title
}) => {
	// const { REACT_APP_PATH } = process.env;
	const desktop = useMediaQuery(theme => theme.breakpoints.up("md"));
	// const [localValue, setLocalValue] = useState(rangeSelected);
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const today = new Date();
	const minDate = new Date(today.setDate(new Date().getDate() + 1));

	const params = [];

	if (providerID) {
		params.push(`providerid=${providerID}`);
	}

	if (productID) {
		params.push(`productid=${productID}`);
	}

	return (
		<Suspense fallback={<></>}>
			<ReactOverlay
				avoidCloseOnOutsideClickAndESC
				className="datepicker"
				footer={
					<Buttons
						confirmLabel={l("quicksearch.datepickerButtonConfirm")}
						resetLabel={l("quicksearch.datepickerButtonReset")}
						onConfirm={() => {
							setRangeSelected(rangeSelected);
							setOpen(false);
						}}
						onReset={() => {
							setOpen(false);
						}}
					/>
				}
				mode="html"
				noPadding={false}
				setOpen={setOpen}
				size="large"
				title={title}
				toolbarColor="primary"
			>
				<ReactDatepicker
					lang={lang}
					minDate={minDate}
					months={desktop ? 2 : 1}
					range
					rangeSelected={rangeSelected}
					setRangeSelected={setRangeSelected}
				/>
			</ReactOverlay>
		</Suspense>
	);
};

export default Datepicker;
