import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		"& > div:last-child a": {
			borderBottom: 0
		},
		"& .mco-view-component-header-menu-mobilemenu-level1--active": {
			paddingBottom: theme.spacing(2),
			"& > a > .mco-view-component-header-menu-mobilemenu-menuitem__arrow": {
				transform: "rotate(180deg)"
			}
		}
	},
	hideOnDesktop: {
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	},
	submenuActive: {
		"& > div": {
			opacity: 0.5
		},
		"& .mco-view-component-header-menu-mobilemenu-level1--active": {
			opacity: 1
		}
	}
}));

export default styles;
