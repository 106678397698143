import makeStyles from "@material-ui/styles/makeStyles";

const styles = (colorTheme, theme) => {
	let backgroundStyles = null;

	switch (colorTheme) {
		case "Rot":
			backgroundStyles = {
				background: theme.palette.brand.brand7
			};
			break;
		case "Violet":
			backgroundStyles = {
				background: theme.palette.brand.brand5
			};
			break;
		case "Grün":
			backgroundStyles = {
				background: theme.palette.brand.brand4
			};
			break;
		case "Blau":
			backgroundStyles = {
				background: theme.palette.brand.brand6
			};
			break;
		case "Gelb":
			backgroundStyles = {
				background: theme.palette.brand.brand3
			};
			break;
		default:
			backgroundStyles = {
				background: theme.palette.background.body
			};
	}

	return makeStyles(theme => ({
		header: {
			...backgroundStyles,
			left: 0,
			position: "sticky",
			right: 0,
			top: 0,
			zIndex: 100
		},
		headerRoomDetail: {
			background: theme.palette.brand.brand6
		},
		stickyHeader: {
			background: theme.palette.background.header,
			borderBottom: "1px solid " + theme.palette.divider
		},
		menuActive: {
			background: theme.palette.background.menu
		},
		container: {
			alignItems: "center",
			display: "flex",
			gap: theme.spacing(1),
			height: 60,
			[theme.breakpoints.up("sm")]: {
				gap: 12
			},
			[theme.breakpoints.up("md")]: {
				gap: theme.spacing(2),
				height: 88
			},
			"& .mco-view-component-header__lang": {
				display: "none",
				[theme.breakpoints.up("md")]: {
					display: "block"
				}
			}
		},
		icons: {
			alignItems: "center",
			display: "flex",
			gap: theme.spacing(1),
			[theme.breakpoints.down("sm")]: {
				"& .mco-shop-user-icon": {
					height: 24,
					width: 24,
					"& svg": {
						width: "24px !important"
					}
				},
				"& .mco-shop-bookmark-icon": {
					height: 24,
					width: 24,
					"&__badge": {
						"& span": {
							fontSize: 10,
							height: 16,
							minWidth: 16,
							right: 3
						}
					},
					"& svg": {
						width: "22px !important"
					}
				},
				"& .mco-shop-cart-icon": {
					height: 24,
					width: 24,
					"&__badge": {
						"& span": {
							fontSize: 10,
							height: 16,
							minWidth: 16,
							right: 3
						}
					},
					"& svg": {
						width: "22px !important"
					}
				}
			}
		}
	}))();
};

export default styles;
