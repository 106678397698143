import React, { useContext, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./Search.styles";

const ReactSearchOverlay01 = loadable(() =>
	import("@micado-digital/react-search-overlay/ReactSearchOverlay01")
);

const Search = () => {
	const css = styles();
	const [searchOpen, setSearchOpen] = useState(false);
	const [pageData] = useContext(PageContext);
	const sysLink = useSystemLinks();
	const { lang } = pageData;

	return (
		<>
			<div
				className={clsx(css.search, "mco-view-component-header-search")}
				onClick={() => setSearchOpen(true)}
			>
				<ReactSVGIcon color="primary" src="/img/icons/search.svg" size={24} />
			</div>
			{searchOpen && (
				<ReactSearchOverlay01
					lang={lang}
					setSearchOpen={setSearchOpen}
					targetLink={sysLink("search")}
				/>
			)}
		</>
	);
};

export default Search;
