export const theme = {
	typography: {
		fontFamily: "Gatter Sans",
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontFamily: "Gatter Sans",
			fontSize: "clamp(19px, 4vw, 23px)",
			fontWeight: 700,
			lineHeight: 1.5,
			textTransform: "uppercase"
		},
		h2: {
			fontFamily: "Gatter Sans",
			fontSize: "clamp(22px, 4vw, 29px)",
			fontWeight: 400,
			lineHeight: 1.5
		},
		h3: {
			fontFamily: "Gatter Sans",
			fontSize: "clamp(17px, 8vw, 20px)",
			fontWeight: 700,
			lineHeight: 1.5,
			textTransform: "uppercase"
		},
		h4: {
			fontFamily: "Gatter Sans",
			fontSize: "clamp(15px, 8vw, 16px)",
			fontWeight: 700,
			lineHeight: 1.5
		},
		h5: {
			fontFamily: "Gatter Sans",
			fontSize: "clamp(15px, 8vw, 16px)",
			fontWeight: 500,
			lineHeight: 1.5
		},
		h6: {
			fontFamily: "Gatter Sans",
			fontSize: "clamp(15px, 8vw, 16px)",
			fontWeight: 400,
			lineHeight: 1.5
		},
		body1: {
			fontFamily: "Gatter Sans",
			fontSize: "clamp(15px, 8vw, 16px)",
			fontWeight: 400,
			lineHeight: 1.5
		},
		body2: {
			fontFamily: "Gatter Sans",
			fontWeight: 500,
			fontSize: "clamp(15px, 8vw, 16px)",
			lineHeight: 1.5
		},
		subtitle1: {
			fontFamily: "Gatter Sans",
			fontWeight: 700,
			fontSize: 16,
			lineHeight: 1.5
		},
		subtitle2: {
			fontFamily: "Gatter Sans",
			fontWeight: 700,
			fontSize: 14,
			lineHeight: 1.5
		},
		overline: {
			fontWeight: 400,
			fontSize: 16,
			letterSpacing: "0.05em",
			lineHeight: 1.5,
			textTransform: "uppercase"
		},
		caption: {
			fontFamily: "Gatter Sans",
			fontWeight: 700,
			fontSize: 13,
			letterSpacing: "1px",
			lineHeight: 1.5,
			textTransform: "uppercase"
		},
		button: {
			fontFamily: "Gatter Sans",
			fontWeight: 700,
			fontSize: 13,
			letterSpacing: "1px",
			lineHeight: 1,
			textTransform: "uppercase"
		}
	},
	palette: {
		primary: {
			light: "#132941",
			main: "#132941",
			dark: "#132941",
			contrastText: "#FFFFFF"
		},
		secondary: {
			light: "#f8e599",
			main: "#FFE271",
			dark: "#F3D76C",
			contrastText: "#132941"
		},
		error: {
			light: "#F08080",
			main: "#D50B0B",
			dark: "#8B0101"
		},
		warning: {
			light: "#FFDE77",
			main: "#FFC105",
			dark: "#9F7804"
		},
		info: {
			light: "#249FF9",
			main: "#0581DC",
			dark: "#035896"
		},
		success: {
			light: "#B2D975",
			main: "#359004",
			dark: "#477301"
		},
		grey: {
			50: "#F9FAFA",
			100: "#F1F3F3",
			200: "#D6DADC",
			300: "#ACB5B9",
			400: "#919CA1",
			500: "#75838A",
			600: "#5E696E",
			700: "#464F53",
			800: "#2F3437",
			900: "#171A1C",
			A100: "rgba(0, 0, 0, 0.1)",
			A200: "rgba(0, 0, 0, 0.3)",
			A400: "rgba(0, 0, 0, 0.5)",
			A700: "rgba(0, 0, 0, 0.8)"
		},
		text: {
			primary: "#132941",
			secondary: "#13294180",
			disabled: "#13294180"
		},
		divider: "#132941",
		background: {
			backdrop: "rgba(0, 0, 0, 0.5)",
			body: "#F3EFE7",
			footer: "#F3EFE7",
			header: "#F3EFE7",
			menu: "#FFE271",
			default: "#F3EFE7"
		},
		brand: {
			brand1: "#132941",
			brand2: "#F3EFE7",
			brand3: "#FFE271",
			brand4: "#9FBD80",
			brand5: "#C9C2D0",
			brand6: "#BCDBE4",
			brand7: "#DA9465"
		},
		consent: {
			background: "#132941"
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				backgroundColor: "#FFE271 !important",
				boxShadow: "none !important",
				color: "#132941 !important",
				fontSize: "13px !important",
				height: 50,
				lineHeight: 1,
				minWidth: 150,
				padding: "11px 24px 8px !important",
				"&:hover": {
					backgroundColor: "#F3D76C !important"
				},
				"@media (min-width: 960px)": {
					height: 56
				}
			},
			containedSizeLarge: {
				backgroundColor: "#FFE271 !important",
				boxShadow: "none !important",
				height: 50,
				lineHeight: 1,
				minWidth: 150,
				padding: "11px 24px 8px !important",
				"&:hover": {
					backgroundColor: "#F3D76C !important"
				},
				"@media (min-width: 960px)": {
					height: 56
				}
			},
			outlined: {
				background: "none !important",
				borderColor: "#132941 !important",
				borderWidth: "1.5px !important",
				transition:
					"background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
				"&:hover": {
					background: "#132941 !important",
					borderColor: "#132941 !important",
					borderWidth: "1.5px !important",
					color: "#F3EFE7 !important"
				}
			},
			text: {
				background: "none !important",
				minWidth: "auto !important",
				padding: "0 !important",
				textDecoration: "underline !important",
				textUnderlineOffset: "8px !important",
				textDecorationThickness: "1.5px !important",
				"&:hover": {
					background: "none !important",
					opacity: 0.8
				}
			}
		},
		MuiAlert: {
			root: {
				alignItems: "center"
			}
		},
		MuiContainer: {
			root: {
				paddingLeft: "16px !important",
				paddingRight: "16px !important"
			}
		}
	},
	customTypography: {
		menu: {
			mobile: {
				level1: {
					fontFamily: "Junicode",
					fontWeight: 400,
					fontSize: 30,
					lineHeight: 1.1
				},
				level2: {
					fontFamily: "Gatter Sans",
					fontWeight: 700,
					fontSize: 13,
					letterSpacing: "1px",
					lineHeight: 1.5,
					textTransform: "uppercase"
				}
			},
			desktop: {
				level1: {
					fontFamily: "Junicode",
					fontSize: "clamp(48px, 8vw, 82px)",
					fontWeight: 400,
					lineHeight: 1.1,
					"@media (min-width: 960px) and (max-height: 780px)": {
						fontSize: "clamp(48px, 8vw, 72px)"
					}
				},
				level2: {
					fontFamily: "Gatter Sans",
					fontWeight: 400,
					fontSize: "clamp(23px, 8vw, 29px)",
					lineHeight: 1.5
				}
			}
		},
		footer: {
			footermenu: {
				fontFamily: "Gatter Sans",
				fontWeight: 700,
				fontSize: 13,
				letterSpacing: "1px",
				lineHeight: 1.5,
				textTransform: "uppercase"
			},
			metamenu: {
				fontFamily: "Gatter Sans",
				fontWeight: 700,
				fontSize: 13,
				letterSpacing: "1px",
				lineHeight: 1.5,
				textTransform: "uppercase"
			}
		}
	}
};
