import React from "react";
import clsx from "clsx";
import useCurrentWeather from "../../../../hooks/useCurrentWeather";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";

import styles from "./Weather.styles";

const Weather = () => {
	const { REACT_APP_PATH } = process.env;
	const sysLink = useSystemLinks();
	const css = styles();

	const current = useCurrentWeather({
		url: `${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.WeatherApi/GetDetailsAsJson.api`
	});
	const weatherIcon = current?.state ? current?.state : `A`;

	if (!sysLink("weather")) {
		return <></>;
	}

	return (
		<div className={clsx("weather-button", css.root)}>
			<ReactLink aria-label="weather" to={sysLink("weather")}>
				<ReactSVGIcon
					src={`/img/icons/weather/${weatherIcon}.svg`}
					size={32}
					color="initial"
				/>
			</ReactLink>
		</div>
	);
};

export default Weather;
