import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {},
	hideOnDesktop: {
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	}
}));

export default styles;
