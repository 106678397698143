import React, { useContext } from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Booking.styles";

const Booking = () => {
	const [pageData] = useContext(PageContext);
	const [ui, setUi] = useContext(UiContext);
	const sysLink = useSystemLinks();
	const { lang = "de" } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	if (!sysLink("booking")) {
		return <></>;
	}

	return (
		<div className={clsx(css.booking, "mco-view-component-header__quicklinks-booking")}>
			<ReactLink
				onClick={e => {
					setUi({ ...ui, menuOpen: false });
				}}
				to={sysLink("booking")}
			>
				<Typography variant="caption">
					<Local identifier="header.booking" l={l} />
				</Typography>
			</ReactLink>
		</div>
	);
};

export default Booking;
