import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	quicklinks: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			gap: theme.spacing(4.5),
			marginRight: theme.spacing(2)
		},
		[theme.breakpoints.down(390)]: {
			"& .mco-view-component-header__quicklinks-booking": {
				display: "none"
			}
		},
		"& .mco-view-component-header__quicklinks-enquiry, .mco-view-component-header__quicklinks-voucher":
			{
				display: "none",
				[theme.breakpoints.up("md")]: {
					display: "block"
				}
			}
	}
}));

export default styles;
