import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(7),
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(7)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(7)
		},
		[theme.breakpoints.up("md")]: {
			gap: theme.spacing(10),
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(10.5)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(10.5)
			},
			"& .mco-gallery-05__item .mco-gallery-05__item-text": {
				fontSize: 29,
				fontWeight: 400,
				lineHeight: 1.38
			},
			"& .mco-list-01__headline": {
				marginBottom: theme.spacing(3) + "px !important"
			}
		},
		"& .mco-view-component-container-extension-rating:last-child": {
			margin: 0 + " !important"
		}
	},
	intro: {
		background: theme.palette.brand.brand6,
		paddingTop: theme.spacing(1),
		[theme.breakpoints.up("md")]: {
			paddingTop: theme.spacing(4)
		},
		"& > .mco-view-component-container": {
			[theme.breakpoints.up("md")]: {
				display: "grid",
				gridGap: theme.spacing(5),
				gridTemplateColumns: "60% 1fr",
				gridTemplateRows: "1fr"
			},
			[theme.breakpoints.up("lg")]: {
				gridGap: theme.spacing(7),
				gridTemplateColumns: "800px 1fr"
			}
		}
	},
	textWrapper: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		marginBottom: theme.spacing(5),
		"& > p": {
			fontFamily: "'Junicode'",
			fontSize: 34,
			fontWeight: 400,
			letterSpacing: "-0.2px",
			lineHeight: 1.05,
			[theme.breakpoints.up("md")]: {
				fontSize: 58,
				letterSpacing: "-0.4px"
			}
		},
		[theme.breakpoints.up("md")]: {
			marginBottom: 0,
			order: 2
		}
	},
	facts: {
		display: "grid",
		gridColumnGap: theme.spacing(2),
		gridTemplateRows: "1fr",
		gridTemplateColumns: "1fr 1px 1fr",
		marginTop: theme.spacing(4),
		[theme.breakpoints.up("md")]: {
			gridColumnGap: theme.spacing(2.5),
			marginBottom: theme.spacing(5),
			marginTop: "auto"
		},
		[theme.breakpoints.up("lg")]: {
			gridColumnGap: theme.spacing(4)
		},
		"& div": {
			alignItems: "center",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			paddingTop: theme.spacing(0.5)
		},
		"& p": {
			textTransform: "unset"
		}
	}
}));

export default styles;
