import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	lang: {
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		padding: theme.spacing(0.5),
		width: 32
	},
	label: {
		fontSize: 13,
		fontWeight: 700,
		letterSpacing: 1,
		paddingTop: 4,
		textTransform: "uppercase"
	}
}));

export default styles;
