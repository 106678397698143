import makeStyles from "@material-ui/styles/makeStyles";

const styles = (colorTheme, theme) => {
	let backgroundStyles = null;

	switch (colorTheme) {
		case "Rot":
			backgroundStyles = {
				background: theme.palette.brand.brand7
			};
			break;
		case "Violet":
			backgroundStyles = {
				background: theme.palette.brand.brand5
			};
			break;
		case "Grün":
			backgroundStyles = {
				background: theme.palette.brand.brand4
			};
			break;
		case "Blau":
			backgroundStyles = {
				background: theme.palette.brand.brand6
			};
			break;
		case "Gelb":
			backgroundStyles = {
				background: theme.palette.brand.brand3
			};
			break;
		default:
			backgroundStyles = {
				background: theme.palette.background.body
			};
	}

	return makeStyles(theme => ({
		animation: {
			...backgroundStyles,
			position: "relative",
			"& .mco-animation-01__wrapper": {
				margin: "0 auto",
				maxWidth: 1280,
				padding: theme.spacing(1, 2, 0),
				[theme.breakpoints.up("md")]: {
					padding: theme.spacing(4, 2, 0)
				}
			},
			"& .mco-animation-01__slider": {
				display: "grid",
				gridTemplateColumns: "1fr",
				gridTemplateRows: "1fr"
			},
			"& .mco-animation-01__slide-item": {
				alignItems: "flex-start",
				bottom: "auto",
				display: "grid",
				gridGap: theme.spacing(5),
				gridColumn: "1 / 2",
				gridRow: "1 / 2",
				gridTemplateColumns: "1fr",
				gridTemplateRows: "auto",
				left: "auto",
				position: "relative",
				right: "auto",
				top: "auto",
				transition: theme.transitions.create("opacity", {
					easing: theme.transitions?.easing?.easeIn,
					duration: 1000
				}),
				width: "100%",
				[theme.breakpoints.up("md")]: {
					gridTemplateColumns: "50% 1fr",
					gridTemplateRows: "1fr"
				},
				[theme.breakpoints.up("lg")]: {
					gridTemplateColumns: "1fr 520px"
				},
				"& .mco-animation-01__arrows": {
					opacity: 0,
					display: "flex",
					gap: theme.spacing(1),
					marginBottom: theme.spacing(2.5),
					transform: "translate(0, 10px)",
					transition: theme.transitions.create(["transform", "opacity"], {
						easing: theme.transitions?.easing?.easeIn,
						duration: 500
					})
				},
				"& .mco-animation-01__arrows--show": {
					opacity: 1,
					transform: "translate(0, 0)"
				},
				"& .mco-animation-01__image": {
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					marginTop: "auto",
					gridRow: "2 / -1",
					[theme.breakpoints.up("md")]: {
						gridColumn: "2 / -1",
						gridRow: "1 / 2",
						height: "100%"
					},
					"& picture": {
						background: "rgba(243, 239, 231, 0.8)",
						display: "flex",
						position: "relative",
						zIndex: 1,
						"& img": {
							height: "auto !important",
							marginTop: "auto",
							mixBlendMode: "multiply",
							[theme.breakpoints.up("md")]: {
								height: "100%",
								marginTop: 0
							}
						}
					}
				}
			},
			"& .mco-animation-01__slide-item-background": {
				opacity: 0,
				transition: theme.transitions.create("opacity", {
					easing: theme.transitions?.easing?.easeIn,
					duration: 1000
				}),
				"& .mco-animation-01__texts": {
					...backgroundStyles
				}
			},
			"& .mco-animation-01__slide-item--active.mco-animation-01__slide-item-background": {
				opacity: 1
			},
			"& .mco-animation-01__texts": {
				gridRow: "1 / 2",
				position: "relative",
				[theme.breakpoints.up("md")]: {
					gridColumn: "1 / 2",
					gridRow: "1 / 2",
					paddingBottom: theme.spacing(10)
				},
				"& .mco-animation-01__text": {
					fontFamily: "'Junicode'",
					fontSize: 38,
					fontWeight: 400,
					lineHeight: 1.05,
					letterSpacing: "-0.2px",
					textTransform: "unset",
					[theme.breakpoints.up("lg")]: {
						fontSize: 58,
						letterSpacing: "-0.4px"
					}
				},
				"& .mco-animation-01__text.mco-animation-01__text--1-small": {
					fontSize: 38,
					[theme.breakpoints.up("lg")]: {
						fontSize: 45
					}
				},
				"& .mco-animation-01__text--2": {
					fontSize: 16,
					fontWeight: 500,
					lineHeight: 1.05,
					letterSpacing: 0,
					marginTop: theme.spacing(2)
				}
			},
			[theme.breakpoints.up("md")]: {
				minHeight: 356,
				"& .mco-animation-01__arrows": {
					bottom: theme.spacing(6),
					display: "flex",
					gap: theme.spacing(1),
					left: theme.spacing(2),
					opacity: 0,
					position: "absolute",
					transform: "translate(0, 10px)",
					transition: theme.transitions.create(["transform", "opacity"], {
						easing: theme.transitions?.easing?.easeIn,
						duration: 500
					}),
					zIndex: 1
				},
				"& .mco-animation-01__arrows--show": {
					opacity: 1,
					transform: "translate(0, 0)"
				}
			},
			[theme.breakpoints.up("lg")]: {
				minHeight: 550
			}
		},
		bigAnimation: {
			[theme.breakpoints.up("md")]: {
				"& .mco-animation-01__slide-item": {
					gridTemplateColumns: "60% 1fr",
					gridTemplateRows: "1fr",
					"& .mco-animation-01__image": {
						gridColumn: "1 / 2",
						gridRow: "1 / 2"
					},
					"& .mco-animation-01__texts": {
						gridColumn: "2 / -1"
					}
				},
				"& .mco-animation-01__arrows": {
					bottom: theme.spacing(6),
					opacity: 0,
					display: "flex",
					gap: theme.spacing(1),
					left: "calc(60% + 40px)",
					position: "absolute",
					transform: "translate(0, 10px)",
					transition: theme.transitions.create(["transform", "opacity"], {
						easing: theme.transitions?.easing?.easeIn,
						duration: 500
					}),
					zIndex: 2
				},
				"& .mco-animation-01__arrows--show": {
					opacity: 1,
					transform: "translate(0, 0)"
				}
			},
			[theme.breakpoints.up("lg")]: {
				"& .mco-animation-01__slide-item": {
					gridGap: theme.spacing(7),
					gridTemplateColumns: "800px 1fr"
				},
				"& .mco-animation-01__arrows": {
					left: "calc(800px + 68px)"
				}
			}
		},
		hasBreadcrumbs: {
			marginBottom: theme.spacing(5)
		},
		weatherButton: {
			bottom: theme.spacing(2),
			position: "absolute",
			right: theme.spacing(1),
			zIndex: 1,
			[theme.breakpoints.up("md")]: {
				bottom: theme.spacing(3),
				right: theme.spacing(3)
			}
		},
		breadcrumbs: {
			bottom: theme.spacing(-5),
			left: theme.spacing(0),
			maxWidth: 1280,
			padding: theme.spacing(0, 2),
			position: "absolute",
			right: theme.spacing(0),
			[theme.breakpoints.up("md")]: {
				bottom: theme.spacing(0.5),
				marginLeft: "auto",
				marginRight: "auto",
				right: "calc(50% - 24px)",
				zIndex: 1
			},
			[theme.breakpoints.up("lg")]: {
				right: 0
			}
		},
		breadcrumbsRight: {
			[theme.breakpoints.up("md")]: {
				left: "calc(60% + 24px)",
				right: 0
			},
			[theme.breakpoints.up("lg")]: {
				left: 0,
				paddingLeft: 856
			}
		}
	}))();
};

export default styles;
