import React from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactLink from "@micado-digital/react-link/ReactLink";

import styles from "./Logo.styles";

const Logo = () => {
	const screenMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const css = styles();

	const Logo = () => {
		return (
			<ReactLink className={clsx(css.logo, "mco-view-component-header-logo")} to="/">
				<img
					alt="Biohotel Rupertus"
					className={clsx(css.image, "mco-view-component-header-logo__image")}
					height={screenMD ? 24 : 20}
					src="/img/logos/logo-rupertus.svg"
					width={screenMD ? 165 : 138}
				/>
			</ReactLink>
		);
	};

	return <div className={clsx(css.logo, "mco-view-component-header__logo")}>{Logo()}</div>;
};

export default Logo;
