import React, { useEffect, useState } from "react";
import clsx from "clsx";
import format from "date-fns/format";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import useLink from "@micado-digital/react-link/hooks/useLink";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import DateField from "./DateField";
import Datepicker from "./Datepicker";
import Adults from "./Adults";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./QuickSearch.styles";

const QuickSearch = ({ code, lang = "de", productID, providerID, showAvailabilities }) => {
	const css = styles();
	const [rangeSelected, setRangeSelected] = useState({});
	const [adults, setAdults] = useState(2);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const screenSM = useMediaQuery(theme => theme.breakpoints.up("sm"));
	const sysLink = useSystemLinks();
	const navigate = useLink();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	useEffect(() => {
		// set default datepicker range values

		const today = new Date();
		today.setHours(0, 0, 0, 0);

		setRangeSelected({
			from: new Date(today.setDate(new Date().getDate() + 1)),
			to: new Date(today.setDate(new Date().getDate() + 8))
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDateClick = () => {
		setOpenDatePicker(true);
	};

	const handleAdultsChange = value => {
		setAdults(value?.counter);
	};

	const handleSubmit = () => {
		const checkIn = format(rangeSelected?.from, "yyyy-MM-dd", { locale });
		const checkOut = format(rangeSelected?.to, "yyyy-MM-dd", { locale });

		const params = [];

		params.push(`calendar_date_from=${checkIn}`);
		params.push(`calendar_date_to=${checkOut}`);
		params.push(`persons_adults=${adults}`);

		navigate(`${sysLink("booking") + "#/?" + params.join("&")}`);
	};

	const locale = lang === "de" || lang === "it" ? de : enUS;

	return (
		<>
			<div className={clsx(css.root, "mco-quicksearch")}>
				<DateField
					label="Check in"
					lang={lang}
					placeholder=""
					value={{
						fullDate: rangeSelected?.from
							? format(rangeSelected?.from, "P", { locale })
							: null,
						year: rangeSelected?.from ? format(rangeSelected?.from, "yyyy") : null
					}}
					onClick={handleDateClick}
				/>
				<DateField
					label="Check out"
					lang={lang}
					placeholder=""
					value={{
						fullDate: rangeSelected?.to ? format(rangeSelected?.to, "P", { locale }) : null,
						year: rangeSelected?.to ? format(rangeSelected?.to, "yyyy") : null
					}}
					onClick={handleDateClick}
				/>
				<Adults
					name="adults"
					label={l("quicksearch.adults")}
					value={2}
					onChange={handleAdultsChange}
				/>
				{screenSM && <div className={clsx(css.divider, "mco-quicksearch-divider")}></div>}
				<div className={clsx(css.button, "mco-quicksearch-button")}>
					<Button onClick={handleSubmit} variant="contained" color="secondary">
						{l("quicksearch.buttonBooking")}
					</Button>
				</div>
			</div>
			{openDatePicker && (
				<Datepicker
					lang={lang}
					productID={productID}
					providerID={providerID}
					rangeSelected={rangeSelected}
					setRangeSelected={setRangeSelected}
					setOpen={setOpenDatePicker}
					title={l("quicksearch.datepickerTitle")}
				/>
			)}
		</>
	);
};

export default QuickSearch;
